import Move from './Move';
import {ReactComponent as Cart} from './icons/cart-shopping-solid.svg'
import Img from './Img';

import styles from './css/Header.module.css';


function Header() {
  return (
    <header className={styles.header}>
      <a href="/"><Img className={styles.logo} file="logo_s.png" alt="gaimglass logo"/></a>
      <h1 className="gg-seo">Gaimglass</h1>
      <nav className={styles.links}>
        <a href="/#sizing">Sizing</a>
        <a className={styles.start} href="https://gaimglass.com/start/">Get Started</a>
        <a className={styles.calibrate} href="https://gaimglass.com/calibrate/">Calibrate</a>
        <a href="/lab">Aim Lab</a>
        <a href="/#faq">FAQ</a>
        <a href="/shop">Shop</a>
        <a href="/cart"><Cart className={styles.cart}/></a>
      </nav>
    </header>
  );
}

export default Header;

