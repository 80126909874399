import { useInView } from 'react-intersection-observer';


import { useRef, useEffect, useState } from "react";
import classNames from 'classnames';
import styles from './css/Size.module.css';


const useElementOnScreen = (options) => {
  const containerRef = useRef(null)
  const [ isVisible, setIsVisible ] = useState(false)

  const callbackFunction = (entries) => {
    const [ entry ] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)
    
    return () => {
      if(containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])

  return [containerRef, isVisible]
}


function Size(props) {

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: .99,
  });

  
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const [inViewClass, setInViewClass] = useState(false);


  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.99
  });

 
  useEffect(() => {

    if (isVisible && !inViewClass) {
      // show
      setTimeout(()=>{
        setInViewClass(true); 
        setHasBeenViewed(true);
      }, props.delay)
      
    } else {
      // hide
      if (!hasBeenViewed || !props.sticky) {
        setInViewClass(false);
      }
    }
  }, [isVisible, hasBeenViewed, props.delay, props.sticky]);


  return (
    <div ref={containerRef} style={{
      width: inViewClass ? props.to : props.from
    }}className={classNames(props.className, styles.sizable, {
      [styles['in-view']]: inViewClass,
      [styles['inline']]: props.inline,
    })}>
      {props.children}
    </div>
  );
}

Size.defaultProps = {
  className: '',
  to: '',
  from: '',
  sticky: true, // animate only once when true
  delay: 0,
  inline: true
};


export default Size;

