import Move from './Move';
import Img from './Img';

import styles from './css/Sizing.module.css';

import {ReactComponent as Check} from './icons/check-mark-circle-icon.svg';
import {ReactComponent as X} from './icons/close-round-icon.svg';

function Yes() {

  return <Check className={styles.yes}/>
}

function Long() {
  return <Check className={styles.long} fill="#2cb513"/>
}

function No() {
  return <X className={styles.no} fill="#2cb513"/>
}


function SizingSection() {
  
  return (
    <>
    <section  id="sizing" name="sizing" className={styles.section}>
      <div className={styles.frame}>
        <div className={styles.header}>
          <Move delay="0" slideDirection="left"><h2>Monitor Compatibility</h2></Move>
        </div>
        <table className={styles.sizing}>
          <thead>
            <tr>
              <td>Monitor Size</td>
              <td>Standard Gaimglass (16.8") </td>
              <td>Long Gaimglass (19.8")</td>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.rowTitle}>
              <td colSpan={3}>Widescreen</td>
            </tr>
            <tr>
              <td>24" <span className={styles.ratio}>(16:9)</span></td>
              <td><Yes></Yes></td>
              <td><No></No></td>
            </tr>
            <tr>
              <td>27" <span className={styles.ratio}>(16:9)</span></td>
              <td><Yes></Yes></td>
              <td><Long></Long></td>
            </tr>
            <tr>
              <td>28" <span className={styles.ratio}>(16:9)</span></td>
              <td><Yes></Yes></td>
              <td><Long></Long></td>
            </tr>
            <tr>
              <td>32" <span className={styles.ratio}>(16:9)</span></td>
              <td><No></No></td>
              <td><Long></Long></td>
            </tr>
            <tr className={styles.rowTitle}>
              <td colSpan={3}>Ultra Widescreen</td>
            </tr>
            <tr>
              <td>34" <span className={styles.ratio}>(21:9)</span></td>
              <td><Yes></Yes></td>
              <td><Long></Long></td>
            </tr>
            <tr>
              <td>38" <span className={styles.ratio}>(21:9)</span></td>
              <td><No></No></td>
              <td><Long></Long></td>
            </tr>
            <tr>
              <td>40" <span className={styles.ratio}>(21:9)</span></td>
              <td><No></No></td>
              <td><Long></Long></td>
            </tr>
            <tr className={styles.rowTitle}>
              <td colSpan={3}>Super Ultra Widescreen</td>
            </tr>
            <tr>
              <td>43"<span className={styles.ratio}>(32:9)</span></td>
              <td><Yes></Yes></td>
              <td><No></No></td>
            </tr>
            <tr>
              <td>49"+ <span className={styles.ratio}>(32:9)</span></td>
              <td><Yes></Yes></td>
              <td><Long></Long></td>
            </tr>
            <tr className={styles.rowTitle}>
              <td colSpan={3}>Maximum centerpoint reach: from top edge</td>
            </tr>
            <tr>
              <td>Distance</td>
              <td>7.5"</td>
              <td>9.0"</td>
            </tr>
          </tbody>
        </table>
        <div className={styles.notes}>
          <p>Gaimglass is for computer monitors only, not large TVs or laptop screens. Gaimglass is not compatible with large monitor sizes not included above. Gaimglass is compatible with in-between sizes mentioned above for the respective aspect ratios.</p>
          <p>Some games have offset crosshairs such as Farcry 5 and 6 as well as Destiny 2 for console. For these games, you'll need to change the game setting and disable the reticle offset if possible, or you'll have to measure how far the reticle is in game from the top of your monitor and refer to the "Maximum centerpoint reach" above.
          </p>
        </div>
      </div>
    </section>
    </>
  );
}

export default SizingSection;
