import styles from './css/Faq.module.css';

function Faq() {
  return (
    <section id="faq" name="faq" className={styles.section}>
      <div className={styles.header} >
        <h2 >FAQ</h2>
      </div>
      <div className={`${styles.faq} gg-text-max-size`}>
        <div className={styles.col}>
          <div className={styles.highlight}><strong>Which screen sizes does it work with?</strong></div>
          <p>
          We offer Gaimglass in two sizes to accommodate most monitors. Please read our <a href="#sizing">size specifications</a> for compatibility.
          </p>
          <div className={styles.highlight}><strong>Does Gaimglass fit on curved monitors?</strong></div>
          <p>Yes! 
          </p>
          <div className={styles.highlight}><strong>How do I setup Gaimglass on my monitor?</strong></div>
          <p>Set up is easy and takes just a few seconds. The easiest way is to visit <a href="http://gaimglass.com/start">gaimglass.com/start</a> and follow the steps. The Gaimglass aim plate can be moved up or down by unscrewing the set screw, adjust, and then tighten it back up. You'll only need to do this adjustment the first time and it works for most games. Plug Gaimglass into any USB power source, flip the switch, and you're ready to go!</p>
        
          <div className={styles.highlight}><strong>Which games does it work with?</strong></div>
          <p>Gaimglass is compatible with virtually every first person shooter title and has been shown to have dramatic results on a large number of them.</p>

        </div>
        <div className={styles.col}>     
          <div className={styles.highlight}><strong>Does it work for XBOX or PlayStation or Nintendo?</strong></div>
          <p>Yes! You can run Gaimglass with your consoles as long as you connect them to a <a href="#sizing">compatible monitor</a>. Gaimglass is powered by a USB port that can be plugged into any USB source including a wall outlet.</p>

          <div className={styles.highlight}><strong>Can't I just use stickers?</strong></div>
          <p>Stickers (or markers) are hard to see in dark areas and can conflict with in-game crosshairs. Gaimglass gives you an ultra-bright illuminated and 3D aim dot that is clearly distinguishable on your monitor. Give Gaimglass a try. You won't go back.</p>

          <div className={styles.highlight}><strong>What about software overlays or monitor crosshairs?</strong></div>
          <p>Gaimglass offers a superior contrast and brightness to anything rendered on your screen. The other big difference is Gaimglass does not render pixels over your game so its visually distinct and small. Most software overlays don't blend well with the game, are fussy to set up, and degrade the experience.
          </p>
          
          <div className={styles.highlight}><strong>How long does it take to get used to using it?</strong></div>
          <p>Gaimglass takes a few days to adjust to. The reason it takes a while is because the aim point sits a few millimeters in front of your focal point so it has a floating effect like a real weapon sight. After about a week, most players can completely adjust to the 3D effect.</p>
        </div>
      </div>
    </section>
  )
}

export default Faq;

