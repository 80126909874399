import React, {useEffect} from 'react'
import Footer from './Footer'


import Header from './Header';
import './css/globalStyles.css';
import appStyles from './css/App.module.css';
import styles from './css/Guides.module.css';


function Card(props) {
  return (
    <div className={styles.card}>
      
        <a href={props.url} className={styles.image} style={{
          'backgroundImage': `url(${props.image})`
        }}>
      </a>  
      
      <div className={`${styles.text} gg-box`}>
        <h2><a href={props.url}>{props.title}</a></h2>
        <h4>{props.subtitle}</h4>
        {props.children}
        <div className={styles.cardFooter}>
          <div className={styles.readTime}>{props.readTime} minute read</div>
          <div className={styles.date}>{props.date}</div>
        </div>
      </div>
    </div>
  )
}

Card.defaultProps = {
  image: '',
  title: '',
  subtitle: '',
  date: '',
  readTime: 5,
};

function Guides() {

  
  useEffect(() => {
    document.title = 'Gaimglass - Aim Labs';
  }, []);
  
  
  return (
    <div className={appStyles.App}>
      <Header></Header>
      <ul className={styles.guides}>
        <li>
            <Card 
              image="https://d2ifmew1yveede.cloudfront.net/site/play-better-small-01.jpg"
              url="/improve-your-kd-part-1"
              title="How to improve your K/D"
              subtitle="Part 1/4"
              readTime={5}
              date={"3/10/32"}
            >
            <p>Improving your skills starts with intent and preparation. Lets go over some of the basics so we can set our expectations and develop a mindset that is tuned for learning.</p>
          </Card>
        </li>
        <li>
            <Card 
              image="https://d2ifmew1yveede.cloudfront.net/site/play-better-small-02.jpg"
              url="/improve-your-kd-part-2"
              title="How to improve your K/D"
              subtitle="Part 2/4"
              readTime={8}
              date={"3/10/32"}
            >
            <p>Good game habits will go a long way to improving your score and skill. Let's define some of these habits and make them second nature.</p>
          </Card>
        </li>
        <li>
            <Card 
              image="https://d2ifmew1yveede.cloudfront.net/site/play-better-small-03.jpg"
              url="/improve-your-kd-part-3"
              title="How to improve your K/D"
              subtitle="Part 3/4"
              readTime={4}
              date={"3/10/32"}
            >
            <p>Great at aiming does not always mean you'll be great at gaming. Being a great player is about being great in all aspects of the game.</p>
          </Card>
        </li>
        <li>
            <Card 
              image="https://d2ifmew1yveede.cloudfront.net/site/play-better-small-04.jpg"
              url="/improve-your-kd-part-4"
              title="How to improve your K/D"
              subtitle="Part 4/4"
              readTime={6}
              date={"3/10/32"}
            >
            <p>Track your data and make losing about learning. Force yourself to play with people better than you. If you have and open mindset and take this seriously then you'll improve quickly.</p>
          </Card>
        </li>
      </ul>
      <Footer></Footer>
    </div>
  );
}

export default Guides;


