import Move from './Move';
import classNames from 'classnames';


const baseUrl = 'https://d2ifmew1yveede.cloudfront.net/site/v2'
function Img(props) {
  return (
    <img className={props.className} src={`${baseUrl}/${props.file}`} alt={props.alt}/>
  );
}


Img.defaultProps = {
  src: '',
  className: '',
  alt: ''
};

export default Img;
