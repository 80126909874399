import Move from './Move';

import styles from './css/Video.module.css';


function VideoSection() {
  
  return (
    <>
    
    <section className={styles.section}>
      <div className={styles.header}>
        {/* <Move delay="0" slideDirection="right"><h2>FLAT</h2></Move>
        <Move delay="200"><h3 className={styles.spacer}>or</h3></Move>
        <Move delay="400" slideDirection="left"><h2>CURVED</h2></Move> */}
      </div>
      <div className={styles.videoWrapper}>
        <div className={styles.video}>
          <iframe src="https://www.youtube.com/embed/zsFOkyFDC8g?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen=""></iframe>
        </div>
      </div>
    </section>
    </>
  );
}

export default VideoSection;
