import Move from './Move';

import styles from './css/Target.module.css';

function Header() {
  return (
    
    <div className={styles.title}>
      <Move delay="200" slideDirection="right">
        <h2 className={`${styles.header} ${styles.targets}`}>
          HUNT <em>TARGETS</em>
        </h2>
      </Move>
      <Move delay="500"  slideDirection="right">
        <h2 className={`${styles.header} ${styles.crosshairs}`}>
          NOT <em>CROSSHAIRS</em>
        </h2>
        
      </Move>
    </div>
  ) 
}

function TargetSection() {
  
  const text = `Crosshairs in modern FPS games can often have poor visibility and contrast especially with highly detailed textures. Gaimglass enhances your peripheral vision so aiming becomes more accurate and faster because you never lose the center point when looking away.`

  
  return (
    <>
      <section className={styles.mobileSectionHeader}>
        <Header></Header>
        <p>
          {text}
        </p>
      </section>
      <section className={styles.section}>
        <div className={styles.content}>
          <div className={styles.title}>
            <Header></Header>
          </div>
          <div className={`gg-box ${styles.box}`}>
            <p>{text}</p>
          </div> 
        </div>
      </section>
      {/* <section className={styles.mobileSectionText}>
        <p>
          {text}
        </p>
      </section> */}
    </>
  );
}

export default TargetSection;
