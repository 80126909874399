import Move from './Move';
import Img from './Img';

import styles from './css/Flat.module.css';


function FlatSection() {
  
  return (
    <>
    
    <section className={styles.section}>
    <div className={styles.header}>
      <Move delay="0" slideDirection="right"><h2>FLAT</h2></Move>
      <Move delay="200"><h3 className={styles.spacer}>or</h3></Move>
      <Move delay="400" slideDirection="left"><h2>CURVED</h2></Move>
      </div>
    </section>
    </>
  );
}

export default FlatSection;
