import Header from './Header';
import Footer from './Footer';

import './css/globalStyles.css';
import styles from './css/App.module.css';

function Empty() {
  
  return (
    <div className={styles.App}>
      <Header></Header>
      <Footer></Footer>
    </div>
  );
}

export default Empty;


