import Move from './Move';
import Amazon from './icons/amazon.png'

import styles from './css/LevelUp.module.css';


function LevelUpSection() {
  
  return (
    <>
    
    <section className={styles.mobileSectionFooter}>
      <div className={styles.header}>
        <Move delay="0" slideDirection="right">
          <h2>Level Up with</h2>
        </Move>
        <h3><span className={styles.gg}>g<span className={styles.green}>aim</span>glass</span></h3>
      </div>
    </section>
    <section className={styles.section}>
      <div className={styles.header}>
        <Move delay="0" slideDirection="right">
          <h2>Level Up with</h2>
        </Move>
        <Move delay="0" slideDirection="up">
        <h3><span className={styles.gg}>g<span className={styles.green}>aim</span>glass</span></h3>
        </Move>
        
      </div>
      <div className={styles.blank}></div>
      <div className={styles.content}>
        <p><div className={styles.price}>Starting at <span className={styles.digits}>$49.95</span></div></p>
        <div className={styles.buy}><a className="gg-button" href="https://gaimglass.com/product/gaimglass/">Buy Now</a></div>
        <a className={styles.amazon} href="https://www.amazon.com/Gaimglass-FPS-Gaming-Aim-Accessory/dp/B07G4D6ZWC"><img alt="amazon" src={Amazon}></img></a>
      </div>
    </section>
    </>
  );
}

export default LevelUpSection;
