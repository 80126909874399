//import useIsInViewport from 'use-is-in-viewport'
import { useInView } from 'react-intersection-observer';


import { useRef, useMemo, useEffect, useState } from "react";
import classNames from 'classnames';
import styles from './css/Move.module.css';

//// try this next https://github.com/zygisS22/intersectionObserverApi/tree/master/src


const useElementOnScreen = (options) => {
  const containerRef = useRef(null)
  const [ isVisible, setIsVisible ] = useState(false)

  const callbackFunction = (entries) => {
    const [ entry ] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)
    
    return () => {
      if(containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])

  return [containerRef, isVisible]
}


function Move(props) {

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: .99,
    // trackVisibility: true,
    // delay: props.delay
  });

  //const targetRef = useRef(null);
  // const [isInViewport, wrappedTargetRef] = useIsInViewport({ 
  //   target: targetRef, 
  //   threshold: 99
  //   //modTop: '1000px',
  //   // modRight: '1em',
  //   // modBottom: '2.5rem',
  //   // modLeft: '10%'
  // });
  
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const [inViewClass, setInViewClass] = useState(false);


  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.99
  });

  //console.log(isVisible);
  
  useEffect(() => {
    //console.log("isVisible", inView);
    
    if (isVisible && !inViewClass) {
      // show
      setTimeout(()=>{
        setInViewClass(true); 
        setHasBeenViewed(true);
      }, props.delay)
      
    } else {
      // hide
      if (!hasBeenViewed || !props.sticky) {
        setInViewClass(false);
      }
    }
  }, [isVisible, hasBeenViewed, props.delay, props.sticky]);


  return (
    <div ref={containerRef} className={classNames(props.className, styles.moveable, {
      [styles['slide-left']]: props.slideDirection === 'left',
      [styles['slide-right']]: props.slideDirection === 'right',
      [styles['slide-up']]: props.slideDirection === 'up',
      [styles['slide-down']]: props.slideDirection === 'down',
      [styles['in-view']]: inViewClass,
      [styles['inline']]: props.inline,
    })}>
      {props.children}
    </div>
  );
}

Move.defaultProps = {
  className: '',
  slideDirection: '',
  sticky: true, // animate only once when true
  delay: 0,
  inline: true
};


export default Move;

