import Move from './Move';

import styles from './css/IntroSection.module.css';
import Amazon from './icons/amazon.png'

function Header() {
  return (
    <>
      <Move delay={200} slideDirection="left"><h1 className={styles.fasterAim}>Faster <span className={styles.aim}>AIM</span></h1></Move>    
      <Move delay={500} slideDirection="left"><h1 className={styles.betterGame}>Better <span className={styles.game}>Game</span>.</h1></Move>
      <Move delay={800} slideDirection="up">  
        <p>Improve your game with the world's best FPS tool</p>
        <div className={styles.action}>
          <div className={styles.price}>Starting at <span className={styles.digits}>$49.95</span></div>
          <div className={styles.shipping}>Free shipping in the US</div>
          <div className={styles.buttons}>
            <div className={styles.buy}><a className="gg-button" href="https://gaimglass.com/product/gaimglass/">Buy Now</a></div>
            <a className={styles.amazon} href="https://www.amazon.com/Gaimglass-FPS-Gaming-Aim-Accessory/dp/B07G4D6ZWC"><img alt="amazon" src={Amazon}></img></a>
          </div>
        </div>  
      </Move>
    </>
  )
}

function IntroSection() {
  
  return (
    <>
    <section className={styles.mobileSectionHeader}>
      <Header></Header>
    </section>
    <section className={styles.section}>
      <div className={styles.content}>
        <Header></Header>
      </div>
    </section>
    </>
  );
}

export default IntroSection;
