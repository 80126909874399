import ReactDOM from "react-dom" 

import styles from './css/Footer.module.css';
import appStyles from './css/App.module.css';
import Discord from './icons/discord.png';
import {ReactComponent as Facebook} from './icons/facebook-f.svg';
import {ReactComponent as Instagram} from './icons/instagram.svg';
import {ReactComponent as YouTube} from './icons/youtube.svg';

function Footer() {
  return (
    <section className={styles.section}>
      <div className={styles.bottom}>
        <div className={styles.socialMediaButtons}>
          <a href="https://www.facebook.com/gaimglass" target="_blank" rel="noreferrer">
            <Facebook className={styles.icon}></Facebook>
          </a>
          
          <a href="https://discord.gg/2uJEueF" target="_blank" rel="noreferrer">
            <img className={`${styles.discord}`} src={Discord} alt="discord"/>
          </a>

          <a href="https://www.instagram.com/gaimglass" target="_blank" rel="noreferrer">
            <Instagram className={styles.icon}></Instagram>
          </a>
          <a href="https://www.youtube.com/channel/UCfc1VfxUCdeZgvi2mMTsuRg" target="_blank" rel="noreferrer">
            <YouTube className={styles.icon}></YouTube>
          </a>
        </div>
        <ul class={styles.bottomLinks}>
          <li>© 2022 gaimglass™</li>
          <li>All rights reserved.</li>
          <li>US Patent: US9901813B2</li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="/shipping-returns">Shipping &amp; Returns</a></li>
          <li><a href="mailto:team@gaimglass.com" target="_blank" rel="noreferrer">team@gaimglass.com</a></li>
        </ul>
      </div>
    </section>
  );
}

function FooterWrapper() {
  const footerWrapper = document.getElementById('react-footer');
  if (footerWrapper) {
    return ReactDOM.createPortal(
      <div className={appStyles.App}>
        <Footer></Footer>
      </div>,
      footerWrapper
    );
  } else {
    // Running react without php (localhost:3000)
    return (<Footer></Footer>);
  }
}

FooterWrapper.defaultProps = {
  className: '',
};

export default FooterWrapper;

