import Move from './Move';
import Img from './Img';

import styles from './css/CloseUp.module.css';


function Header() {
  return (
    <Move delay="0" slideDirection="right">
      <h2>Aim on <span className={styles.point}>POINT</span></h2>
    </Move>
  )
}

function CloseUpSection() {
  
  return (
    <>
      <section className={styles.mobileSection}>
        <Header></Header>
      </section>
      <section className={styles.section}>
        <div className={styles.columnLeft}>
            <ul className={styles.features}>
            <Move delay="300" slideDirection="left" inline={false} ><li>1.0mm precision aimpoint</li></Move>
            <Move delay="400" slideDirection="left" inline={false} ><li>5x brighter than monitor</li></Move>
            <Move delay="500" slideDirection="left" inline={false} ><li>3D holographic effect</li></Move>
            <Move delay="600" slideDirection="left" inline={false} ><li>Minimal glass edge impedance</li></Move>
            <Move delay="700" slideDirection="left" inline={false} ><li>Simulates a real weapon dot sight</li></Move>
            </ul>
        </div>
        <div className={styles.columnRight}>
          <Header></Header>
        </div>
      </section>
    </>
  );
}

export default CloseUpSection;

