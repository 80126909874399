import Move from './Move';

import styles from './css/Detail.module.css';

function Header() {
  return (
    <div className={styles.header}>
      <Move delay="200" slideDirection="right">
        <h2 className={styles.designed}>Designed by <span className={styles.pro}>PRO</span> <span className={styles.gamers}>GAMERS</span></h2></Move>
        <Move className={styles.enhance} delay="200" slideDirection="right"><p>to enhance your skills and improve your scores</p>
        </Move>
    </div>
  )  
}

function DetailSection() {
  
  const text = `Few other accessories unlock you potential as much as Gaimglass. A better mouse or keyboard or controller won't have much measurable effect, but adding a Gaimglass in most cases will. Players tend to see 10% increase in their scores after the first week.`

  return (
    <>
      <section className={styles.mobileSectionHeader}>
        <Header></Header>
        <p>{text}</p>
      </section>
      <section className={styles.section}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Header></Header>
          </div>
          <div className={`gg-box ${styles.box}`}>
            <p>{text}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailSection;
