import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom';

import IntroSection from './IntroSection';
import TargetSection from './TargetSection';
import ImmerseSection from './ImmerseSection';

import DetailSection from './DetailSection';
import CloseUpSection from './CloseUpSection';
import FlatSection from './FlatSection';
import SizingSection from './SizingSection';
import GamesSection from './GamesSection'
import PcOrConsoleSection from './PcOrConsoleSection'
import VideoSection from './VideoSection'
import LevelUpSection from './LevelUpSection'
 import FaqSection from './FaqSection'
import Footer from './Footer'


import Header from './Header';
import './css/globalStyles.css';
import styles from './css/App.module.css';


function App() {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  
  return (
    <div className={styles.App}>
      <Header></Header>
      <IntroSection></IntroSection>
      <TargetSection></TargetSection>
      
      <DetailSection></DetailSection>
      <CloseUpSection></CloseUpSection>
      <ImmerseSection></ImmerseSection>
      <FlatSection></FlatSection>
      <PcOrConsoleSection></PcOrConsoleSection>
      <SizingSection></SizingSection>
      <GamesSection></GamesSection>
      <VideoSection></VideoSection>
      <LevelUpSection></LevelUpSection>
      <FaqSection></FaqSection>
      <Footer></Footer>
    </div>
  );
}

export default App;


