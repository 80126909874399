import Move from './Move';
import Size from './Size';
import Img from './Img';
import { useEffect, useMemo, useState } from 'react';

import styles from './css/Games.module.css';
import classNames from 'classnames';


let games = [
  'apex',
  'wz',
  'bf1',
  'r6',
  'd2',
  'halo',
  'tf2',
  'cs',
  'cod',
]
function GamesSection() {

  let Images = games.map((game, key) => {
    const lastImage = key === games.length -1;
    // console.log(lastImage, key, games.length);
    return <Size to="100%" from="90%"><img 
      className={classNames({
        [styles.lastImage]: lastImage
      })}
      key={key} 
      src={`https://d2ifmew1yveede.cloudfront.net/site/v2/gg2_games_${game}.jpg`}></img></Size>
    })

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <Move delay="0" slideDirection="right">
          <h2>Perfect for Any <span className={styles.fps}>FPS</span> Title</h2>
        </Move>
      </div>
      <div className={styles.header}>
      </div>
      <div className={styles.games}>
        {Images}
      </div>
    </section>
    
  );
}

export default GamesSection;

