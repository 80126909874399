import Move from './Move';

import styles from './css/PcOrConsole.module.css';


function PcOrConsoleSection() {
  const text = `Gaimglass is completely agnostic of your gaming system, so its perfectly compatible with games on a Xbox, PlayStation, Nintendo or PC system. The only requirement is a USB port for power and a compatible monitor listed below.`;

  return (
    <>
    <section className={styles.section}>
      <div className={styles.header}>
        <Move delay="0" slideDirection="right"><h2 className={styles.pc}>PC</h2></Move>
        <Move delay="200"><h3 className={styles.spacer}>or</h3></Move>
        <Move delay="400" slideDirection="left"><h2 className={styles.console}>Console</h2></Move>
      </div>
      <div className={styles.blank}></div>
      <div className={styles.content}>
        <div className={`gg-box ${styles.box}`}>
          <p>{text}</p>
        </div>
      </div> 
    </section>
    <section className={styles.mobileSectionFooter}>
      <p>{text}</p>
    </section>
    </>
  );
}

export default PcOrConsoleSection;
